const smoother = ScrollSmoother.create({
  smooth: 1,
  effects: true
})

window.addEventListener('load', function () {

  // Load overlay
  const loaderOverlay = document.getElementById('loader-overlay')
  loaderOverlay.classList.add('complete')

  // Event listener for transitionend
  loaderOverlay.addEventListener('transitionend', hideElement, false)
  loaderOverlay.addEventListener('webkitTransitionEnd', hideElement, false)

  function hideElement() {
    if (this.classList.contains('complete')) {
      this.style.display = 'none'
    }
  }

  //*******************************************************
  // Cover animation
  //*******************************************************
  // Logo
  // gsap.from('#stripe-top', { opacity: 0, scaleY: 0, ease: "power2.inOut", delay: 0.9, duration: 2.5 })
  // gsap.from('#monograma', { opacity: 0, y: 50, ease: "power2.inOut", delay: 0.3, duration: 2.5 })
  gsap.from('#cover-date-dia', { opacity: 0, x: -100, ease: "power2.inOut", delay: 0, duration: 2 })
  gsap.from('#cover-date-mes', { opacity: 0, x: 100, ease: "power2.inOut", delay: 0.3, duration: 2 })
  gsap.from('#cover-date-ano', { opacity: 0, x: -100, ease: "power2.inOut", delay: 0.6, duration: 2 })
  // gsap.from('#cover-date', { opacity: 0, y: 50, ease: "power2.inOut", delay: 1.2, duration: 2.5 })

  // Para cambiar las propiedades de los elementos del cover según el tamaño de la pantalla
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth
  if (viewportWidth < 768) {
    // console.log('La pantalla es mobile');
    // Logo
    gsap.from('#cover-logo', { opacity: 0, y: -50, x: 0, ease: "power2.inOut", delay: 0.9, duration: 2 })
    gsap.from('#cover-back', { scale: 1.1, ease: "expoScale(0.5, 3, power2.out)", duration: 5 })
    gsap.from('#cover-middle', { scale: 1, yPercent: 5, ease: "power2.out", duration: 5 })
    gsap.from('#cover-place', { opacity: 0, y: -50, ease: "power2.inOut", delay: 1.2, duration: 2 })
  } else {
    // console.log('La pantalla es desktop');
    // Logo
    gsap.from('#cover-logo', { opacity: 0, x: -50, ease: "power2.inOut", delay: 0.9, duration: 2 })
    gsap.from('#cover-back', { scale: 1.1, ease: "expoScale(0.5, 3, power2.out)", duration: 5 })
    gsap.from('#cover-middle', { scale: 1, yPercent: 5, ease: "power2.out", duration: 5 })
    gsap.from('#cover-place', { opacity: 0, x: -50, ease: "power2.inOut", delay: 1.2, duration: 2 })
  }

})

const modalBtns = document.querySelectorAll('[data-bs-toggle="modal"]')
const closeModalBtns = document.querySelectorAll('[data-bs-dismiss="modal"]')
const modals = document.querySelectorAll('.modal')
// console.log(modalBtns)

modalBtns.forEach(btn => {
  ['click'].forEach(event => {
    btn.addEventListener(event, e => {
      e.preventDefault()
      smoother.paused(true)
    })
  })
})

closeModalBtns.forEach(btn => {
  ['click'].forEach(event => {
    btn.addEventListener(event, e => {
      e.preventDefault()
      smoother.paused(false)
    })
  })
})

modals.forEach(modal => {
  modal.addEventListener("hidden.bs.modal", e => {
    e.preventDefault()
    smoother.paused(false)
  })
})

const sectionLinks = document.querySelectorAll(".nav-link, .local-nav") // Ajusta el selector aquí

sectionLinks.forEach((link) => {
  link.addEventListener("click", (e) => {
    e.preventDefault()
    // Aquí determinamos el target dependiendo si es un link o un botón
    const target =
      link.getAttribute("href") || link.getAttribute("data-target")
    if (!target) return
    const linkId = target.startsWith("#") ? target.substring(1) : target // Asegúrate de obtener el ID correctamente
    const section = document.getElementById(linkId)
    if (!section) {
      return
    }
    // Aquí puedes utilizar GSAP para navegar hacia la sección deseada
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: section, offsetY: 70 },
      ease: "power2.inOut"
    })
    // Esconde la navegación offcanvas si está presente
    const cachitoNavElement = document.getElementById("cachitoNav")
    if (cachitoNavElement) {
      const offcanvasInstance = bootstrap.Offcanvas.getInstance(cachitoNavElement) || new bootstrap.Offcanvas(cachitoNavElement)
      offcanvasInstance.hide()
    }
    
    // Cierra cualquier modal que esté abierta
    document.querySelectorAll(".modal.show").forEach((modal) => {
      const modalInstance = bootstrap.Modal.getInstance(modal)
      if (modalInstance) {
        modalInstance.hide()
      }
    })
  })
})    
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function(e) {
    return new bootstrap.Tooltip(e)
  })

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  popoverTriggerList.map(function (e) {
    return new bootstrap.Popover(e)
  })

  // Fancybox
  Fancybox.bind("[data-fancybox]", {
    on: {
      reveal: function () {
        smoother.paused(true)
      },
      close: function () {
        smoother.paused(false)
      }
    }
  })

  const footer = document.querySelector('footer')
  const nav = document.querySelector('#main-nav')
  const chat = document.querySelector('#whatsapp-button')
  const navLogo = document.querySelector('#nav-logo')
  const navLogoPaths = document.querySelectorAll('svg#nav-logo path')
  const navLinks = document.querySelectorAll('#main-nav .nav-link')
  const offcanvasNav = document.querySelector('#cachitoNav')
  const footerTexts = document.querySelectorAll('#footer p')
  const footerLinks = document.querySelectorAll('#footer .inline-link')
  const footerLogoPaths = document.querySelectorAll('svg#footer-logo path')

  // Función para actualizar estilos basados en el scroll
  function updateStyles(scrollTop) {
    // Para cambiar colores de varios elementos

    navLinks.forEach(link => {
      link.style.color = scrollTop > 210 ? 'var(--nav-link-scrolled)' : 'var(--nav-link-initial)'
    })
    footerLinks.forEach(link => {
      link.style.color = scrollTop > 210 ? 'var(--footer-link-scrolled)' : 'var(--footer-link-initial)'
    })
    footerLogoPaths.forEach(path => {
      path.style.fill = scrollTop > 210 ? 'var(--footer-logo-scrolled)' : 'var(--footer-logo-initial)'
    })
    footerTexts.forEach(text => {
      text.style.color = scrollTop > 210 ? 'var(--footer-txt-scrolled)' : 'var(--footer-txt-initial)'
    })
    navLogoPaths.forEach(path => {
      path.style.fill = scrollTop > 210 ? 'var(--nav-logo-scrolled)' : 'var(--nav-logo-initial)'
    })
  
    // Para cambiar colores de cada elemento
    if (scrollTop > 210) {
      footer.style.backgroundColor = 'var(--footer-bg-scrolled)'
      nav.style.backgroundColor = 'var(--nav-bg-scrolled)'
      nav.style.backdropFilter = 'var(--nav-blur-scrolled)'
      nav.style.webkitBackdropFilter = 'var(--nav-blur-scrolled)'
      navLogo.style.opacity = '1'
    } else {
      footer.style.backgroundColor = 'var(--footer-bg-initial)'
      nav.style.backgroundColor = 'var(--nav-bg-initial)'
      nav.style.backdropFilter = 'var(--nav-blur-initial)'
      nav.style.webkitBackdropFilter = 'var(--nav-blur-initial)'
      navLogo.style.opacity = '1'
    }
  }

  const dresscodeBtns = document.querySelectorAll('.dresscode-suggestion-btn');
  dresscodeBtns.forEach(btn => {
    const svgElement = btn.querySelector('.dresscode-suggestion-btn-svg');

    // console.log(svgElement);
    // Create our animation
    const rotateBtn = gsap.timeline({
      reversed: true,
      paused: true
    });

    const scaleBtn = gsap.timeline({
      reversed: true
    });

    rotateBtn.to(svgElement, {
      rotation: '360',
      repeat: -1,
      duration: 10,
      ease: 'none'
    });

    scaleBtn.to(svgElement, {
      scale: 1.05,
      duration: 0.3,
      ease: 'power2.inOut'
    });

    btn.addEventListener('mouseover', () => {
      // console.log('entre');
      rotateBtn.play();
      scaleBtn.play();
    })
    btn.addEventListener('mouseout', () => {
      // console.log('sali');
      rotateBtn.pause();
      scaleBtn.reverse();
    })
  })

  // ScrollTrigger para mostrar/esconder elementos
  const showNav = gsap.from(nav, {
    yPercent: -100,
    duration: 0.5,
    paused: true
  }).progress(1)

  const showFooter = gsap.from(footer, {
    yPercent: 100,
    duration: 0.5,
    paused: true
  }).progress(1)

  const showChat = gsap.from(chat, {
    x: 100,
    duration: 0.5,
    paused: true
  }).progress(1)
// Variables para rastrear el estado del scroll
let lastScrollTop = 0
let scrolledDistance = 0
let ticking = false

const updateScroll = (self) => {
  const scrollTop = self.scroll()
  const delta = scrollTop - lastScrollTop
  lastScrollTop = scrollTop

  // Aumentar o resetear la distancia recorrida basada en la dirección del scroll
  if (self.direction === -1) { // Scroll hacia arriba
    scrolledDistance += Math.abs(delta)
    if (scrolledDistance >= 50) {
      if (!offcanvasNav.classList.contains('show')) {
        showNav.play()
      } else {
        showNav.progress()
      }
      showFooter.play()
      showChat.play()
    }
  } else if (self.direction === 1) { // Scroll hacia abajo
    scrolledDistance += Math.abs(delta)
    if (scrolledDistance >= 50) {
      if (!offcanvasNav.classList.contains('show')) {
        showNav.reverse()
      } else {
        showNav.progress()
      }
      showFooter.reverse()
      showChat.reverse()
    }
  }

  // Resetear la distancia recorrida si se invierte la dirección del scroll
  if (scrolledDistance >= 50) {
    scrolledDistance = 0
  }

  // Actualizar estilos basados en el scroll
  updateStyles(scrollTop)

  ticking = false
}

// ScrollTrigger para esconder/mostrar elementos y cambiar estilos
ScrollTrigger.create({
  trigger: '#smooth-content',
  start: "top top",
  end: "max",
  onUpdate: (self) => {
    if (!ticking) {
      requestAnimationFrame(() => updateScroll(self))
      ticking = true
    }
  }
})

  // ==========================================================================
  // COPY TO CLIPBOARD
  // ==========================================================================

  document.addEventListener('DOMContentLoaded', () => {

    // Inicializar ClipboardJS en todos los elementos con 'data-copy'
    const clipboard = new ClipboardJS('[data-copy]')

    clipboard.on('success', function(e) {
        e.clearSelection()

        // Cambiar el tooltip
        const element = e.trigger
        const bsTooltip = bootstrap.Tooltip.getInstance(element)
        const originalTitle = element.getAttribute('data-bs-original-title')
        element.setAttribute('data-bs-original-title', element.getAttribute('data-copy-success'))
        bsTooltip.show()

        // Restablecer el tooltip original después de 2 segundos
        setTimeout(() => {
            element.setAttribute('data-bs-original-title', originalTitle)
            bsTooltip.hide()
        }, 2000)
    })

    clipboard.on('error', function(e) {
        console.error('Error al copiar: ', e)
    })
  })


  // ==========================================================================
  // SWIPER
  // ==========================================================================

  const swiperTimetable = new Swiper("#timetable-widget", {
    spaceBetween: 18,
    slidesPerView: 5,
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: "auto"
      },
      576: {
        slidesPerView: "auto"
      },
      768: {
        slidesPerView: "auto"
      },
      992: {
        slidesPerView: 5
      },
      1200: {
        slidesPerView: 5
      },
      1400: {
        slidesPerView: 5
      }
    }
  })

  // const swiperTimetable = new Swiper("#timetable-widget", {
  //   slidesPerView: 'auto',
  //   spaceBetween: 36,
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev"
  //   }
  // })

  swiperTimetable.on('touchMove', function () {
    $("#tuto-01").remove()
  })


  // BRIDEMAIDS SWIPER
  // const bridemaidsSwiper = new Swiper('#bridemaids-swiper', {
  //   spaceBetween: 10,
  //   slidesPerView: 3,
  //   grabCursor: true,
  //   centeredSlides: true,
  //   loop: true,
  //   initialSlide:0,
  //   loopedSlides: 3
  // })

  // bridemaidsSwiper.on('touchMove', function () {
  //   $("#tuto-bridemaids").remove()
  // })

  // BESTMEN SWIPER
  // const bestmenSwiper = new Swiper('#bestmen-swiper', {
  //   spaceBetween: 10,
  //   slidesPerView: 3,
  //   grabCursor: true,
  //   centeredSlides: true,
  //   loop: true,
  //   initialSlide:0,
  //   loopedSlides: 3
  // })

  // bestmenSwiper.on('touchMove', function () {
  //   $("#tuto-bestmen").remove()
  // })

  const transportSwiper = new Swiper('#transport-swiper', {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    initialSlide: 1,
    loop: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
      scale: 0.6
    }
  })

  transportSwiper.on('touchMove', function () {
    $("#tuto-transport").remove()
  })

  // HOTELS SWIPER
  const sliderHoteles = new Swiper("#sliderHoteles", {
    spaceBetween: 16,
    slidesPerView: 4,
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      576: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 4
      },
      1400: {
        slidesPerView: 4
      }
    }
  })

  sliderHoteles.on('touchMove', function () {
    $("#tuto-02").remove()
  })

  // $('.swiper-filter').on( 'click', 'button', function() {
  //   var filter = $(this).attr('data-filter')

  //   $('#sliderActivities .swiper-slide').css('display', 'none')
  //   $('#sliderActivities .swiper-slide' + filter).css('display', '')
  //   $('.swiper-filter button').removeClass( 'filter-active' )
  //   $( this ).addClass( 'filter-active' )

  //   sliderActivities.updateSize()
  //   sliderActivities.updateSlides()
  //   sliderActivities.updateProgress()
  //   sliderActivities.updateSlidesClasses()
  //   sliderActivities.slideTo(0)
  //   sliderActivities.scrollbar.updateSize()

  //   return false
  // })

  // const sliderActivities = new Swiper("#sliderActivities", {
  //   spaceBetween: 16,
  //   slidesPerView: 4,
  //   clickable: true,
  //   breakpoints: {
  //     320: {
  //       slidesPerView: 1,
  //       spaceBetween: 16
  //     },
  //     576: {
  //       slidesPerView: 1,
  //       spaceBetween: 16
  //     },
  //     768: {
  //       slidesPerView: 2,
  //       spaceBetween: 16
  //     },
  //     992: {
  //       slidesPerView: 2,
  //       spaceBetween: 16
  //     },
  //     1200: {
  //       slidesPerView: 4,
  //       spaceBetween: 16
  //     },
  //     1400: {
  //       slidesPerView: 4,
  //       spaceBetween: 16
  //     }
  //   }
  // })

  // sliderActivities.on('touchMove', function () {
  //   $("#tuto-activities").remove()
  // })

  // ==========================================================================
  // ANIMATIONS
  // ==========================================================================


  // Boxes animation
  //*******************************************************

  const boxDown = gsap.utils.toArray('.animateDown')
  const boxUp = gsap.utils.toArray('.animateUp')
  const boxLeft = gsap.utils.toArray('.animateLeft')
  const boxRight = gsap.utils.toArray('.animateRight')
  const stripeAnimate = gsap.utils.toArray('.stripe-wrapper')

  stripeAnimate.forEach(stripe => {
    gsap.from(stripe, {
      scrollTrigger: {
        // markers: true,
        trigger: stripe,
        start: "top 80%",
        scrub: 3
      },
      scaleY: 0,
      ease:"power2.inOut"
    })
  })

  boxDown.forEach(box => {
    gsap.from(box, {
      scrollTrigger: {
        // markers: true,
        trigger: box,
        start: "top 80%",
        end: "top 60%",
        duration: 1,
        scrub: 2
      },
      opacity: 0,
      y: -50,
      stagger: 0.1,
      scale:0.95,
      ease:"power2.out"
    })
  })

  boxUp.forEach(box => {
    gsap.from(box, {
      scrollTrigger: {
        // markers: true,
        trigger: box,
        start: "top bottom",
        end: "top 80%",
        duration: 1,
        scrub: 2
      },
      opacity: 0,
      y: 50,
      stagger: 0.1,
      scale:0.95,
      ease:"power2.out"
    })
  })

  boxLeft.forEach(box => {
    gsap.from(box, {
      scrollTrigger: {
        // markers: true,
        trigger: box,
        start: "top bottom",
        end: "top 80%",
        duration: 1,
        scrub: 2
      },
      opacity: 0,
      x: -50,
      stagger: 0.1,
      scale:0.95,
      ease:"power2.out"
    })
  })

  boxRight.forEach(box => {
    gsap.from(box, {
      scrollTrigger: {
        // markers: true,
        trigger: box,
        start: "top bottom",
        end: "top 80%",
        duration: 1,
        scrub: 2
      },
      opacity: 0,
      x: 50,
      stagger: 0.1,
      scale:0.95,
      ease:"power2.out"
    })
  })

  // Tween animations
  //*******************************************************

  const galleryImgWrapper = gsap.utils.toArray('#gallery-wrapper [data-fancybox]');

  galleryImgWrapper.forEach(w => {
    gsap.from(w, {
      scrollTrigger: {
        //markers: true,
        trigger: w,
        start: "top bottom",
        end: "top 80%",
        duration: 2,
        scrub: 2
      },
      opacity: 0,
      y: 50,
      stagger: {
        each: 2
      },
      ease: "power2.out"
    })
  })

  // Lo usé en la página de gabrielayrodrigo para dar movimiento a los globos.
  // const flyBalloon = gsap.utils.toArray('.balloon');

  // flyBalloon.forEach(w =>{
  //   gsap.to(w, {
  //     x: "random(-100, 100)",
  //     y: "random(-40, 40)",
  //     rotation: "random(-10, 10)",
  //     duration: 14,
  //     repeat: -1,
  //     ease: "power1.inOut",
  //     yoyo: true,
  //     yoyoEase: true,
  //     stagger: {
  //       each: 0.5,
  //       repeat: -1
  //     }
  //   })
  // })
